<template>
<div>
    <CRow class="row">
        <div class="col-sm-1 label-col">
            <img src="@/assets/images/seller-tools.png" class="st-logo" />  
        </div>
        <div class="col d-flex align-items-center">
            <label class="strong label-col">Seller Tools</label>
        </div>  
    </CRow>
    <div class="form-group form-row mb-0 mt-2">               
        <label class="col-form-label col-sm-1 strong label-col">Authorization Key</label>        
        <span class="col form-control text-primary">{reviewSettings.url}</span>
    </div>   
</div>
</template>
<script>
export default { }
</script>

<style scoped>
.col-sm-1 {
    max-width: 150px;
}
.col-md-1.n2 {
    min-width: 150px;
}
.st-logo {
    max-width: 45px;
    width: 45px;
}
.label-col {
    min-width: 152px;
}
</style>