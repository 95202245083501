<template>
    <div>
        <CLink v-if="!editInternal" class="strong mr-2" @click="onEdit" :disabled="disabledInternal"><CIcon name="cil-pencil" size="sm" class=" ml-1 mr-0 mb-1" /> Edit     
        </CLink>
        <div v-if="editInternal" style="margin-top: -2px; margin-bottom: -2px;">
            <CButton class="edit-btn pb-1 pr-2 mr-2 mt-0 mb-0" color="secondary" size="sm"                    
                    @click="onCancel"
                    :disabled="disabledInternal"
            ><CIcon name="cilX" size="sm" class="ml-0 mr-0 mb-0 mt-0" style="transform: translateY(-2px)" /> Cancel</CButton>
            <CButton class="edit-btn pb-1 pr-2 mt-0 mb-0" color="success" size="sm"
                    @click="onSave"
                    :disabled="disabledInternal"
            ><CIcon name="cil-save" size="sm" class="ml-0 mr-1 mb-0 mt-0" style="transform: translateY(-2px)" /> Save</CButton>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        disabled: Boolean,
        edit: Boolean
    },
    data() {
        return {
            editInternal: true,
            disabledInternal: false
        }
    },
    methods: {
        onEdit() {
            this.editInternal = true;
            this.$emit('edit');
        },
        onCancel() {
            this.editInternal = false;
            this.$emit('cancel');
        },
        onSave() {
            this.$emit('save');
        },        
    },
    watch: {
        disabled(value) {
            this.disabledInternal = value;
        },
        edit(value) {
            this.editInternal = value;
        }
    },
    created() {
        this.disabledInternal = this.disabled;
        this.editInternal = this.edit;
    }
}
</script>
