<template>
<div>
    <CRow class="row">
        <div class="col-sm-1 label-col">
            <img src="@/assets/images/jtl-logo.svg" class="jtl-logo" />  
        </div>
        <div class="col d-flex align-items-center label-col">
            <label class="strong">JTL Adapter</label>
        </div>    
    </CRow>    
</div>
</template>
<script>
export default { }
</script>

<style scoped>
.col-sm-1 {
    max-width: 72px;
}
.jtl-logo {
    width: 51.0265px;
    aspect-ratio: auto 51.0265 / 38;
    height: 38px;
}
.label-col {
    min-width: 152px;
}
</style>