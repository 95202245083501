<template>
<CCard style="min-height: 120px;" class="pb-2"> 
    <CCardHeader class="pt-2 pb-0" style="background: #f8f8ff">  
        <CSwitch v-if="!isLoading" class="float-right mt-1 pt-0 mb-1" color="success" :disabled="true || !canEdit" variant="3d" 
                :checked="channel && channel.isActive" 
        ></CSwitch>  
        <div class="d-flex flex-row align-items-center mt-1 mb-2" style="width:auto">
            <CIcon name="cil-factory" size="lg" class="mr-2" /><h4 class="mb-0">Channel Settings: {{ displayName }}</h4>   
            <CLink to="/channels">  
                <CIcon name="cilActionUndo" size="sm" class="ml-2" style="transform: rotate(90deg)" />          
            </CLink>
        </div>   
    </CCardHeader>
    <CCardBody class="pt-2 pb-2">    
        <CRow v-if='isLoading && channel == null'>  
            <CCol lg="12" class="justify-content-center">
                <CSpinner />
            </CCol>
        </CRow> 
        <CAlert v-if="!isLoading && !channel" color="danger" class="mt-2 mb-0">
            Failed to load channel settings. <CLink class="strong" to="/channels">Click here</CLink> to return to channel list. 
        </CAlert>  
        <template v-if="channel">
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">ChannelType</label>        
                <span  class="col-form-label col">
                    <CIcon v-if="isPage" name="cil-notes" size="sm" class="mr-1 icon-baseline mb-0 mt-0" />
                    <CIcon v-if="!isPage" name="cilMagnifyingGlass" size="sm" class="mr-1 icon-baseline mb-0 mt-0" />
                    {{ channelType }}
                </span>
            </div>     
            <div class="form-group form-row mb-0" v-if="channel.needsProducts">               
                <label class="col-form-label col-sm-1 strong label-col">Product Identifier</label>                        
                <span  class="col-form-label col" :class="{'text-info strong': channel.productIdentifier }">
                    {{ channel.productIdentifier ? channel.productIdentifier : '-' }}
                </span>
            </div>      
            <template v-if="isPage">
                <div class="form-group form-row mb-0 mt-1"> 
                    <label  class="col-form-label col-sm-1 label-col" >
                    <!-- <div class="col-form-label col-sm-1 label-col" > -->                     
                        <strong>Page Name</strong>
                    <!-- </div> -->
                    </label>
                    <div class="col-form-label col-sm-5">                        
                        <span v-if="!channel.pageIsMissing"><CLink :to="`/pages/${channel.pageName}`">{{ channel.pageName }}<CIcon  name="cil-pencil" size="sm" class="ml-1 mr-0 mb-1" /></CLink></span>
                        <span v-else class="text-danger"
                              v-c-tooltip="'The page does not exist.'"
                        >{{ channel.pageName }}<CIcon name="cil-warning" size="sm" class="ml-1 mr-0 mb-1" color="danger" />
                        </span>                        
                        <template v-if="channel.pageLinkExternal && !channel.pageIsMissing">
                            <span class="ml-3">(View online<a :href="channel.pageLinkExternal" target="_blank"><CIcon name="cil-external-link" size="sm" class="ml-1 mr-0 mb-1" /></a>)</span>
                        </template>
                    </div>
                </div>
            </template>
            <div class="form-group form-row mb-0" v-if="channel.needsProducts">
                <div class="col-form-label col">
                    <CLink v-if="canViewProduct"
                           :to="`/products/channel/${channelName}`" 
                           :disabled="isLoading"
                            color="primary" 
                            class="mt-3 strong"
                    ><CIcon name="cil-exit-to-app" class="mr-2 icon-baseline"  />View Products</CLink>
                </div>
            </div>
        </template>     
    </CCardBody>
    <template v-if="channel && !isPage">
        <CCardHeader class="pt-2 pb-2 top-border"  style="background: #f8f8ff">     
            <h6 class="m-0 mt-1 mb-1">Order Lookup</h6>            
        </CCardHeader>  
        <CCardBody class="pb-3">
            <component :is="orderDbComponent"></component>
            <CRow class="row mt-2">
                <div class="form-group form-row mb-0 ml-2">               
                    <label class="col-form-label col-sm-1 strong label-col">Product Dropdown</label>        
                    <CSwitch class="mt-2 ml-1" color="info" variant="3d" size="sm" :checked="channel.needsProducts" disabled />
                </div>  
            </CRow>
            <CRow class="row mt-2">
                <div class="form-group form-row mb-0 ml-2">               
                    <label class="col-form-label col-sm-1 strong label-col">Verify Postcode</label>        
                    <CSwitch class="mt-2 ml-1" color="success" variant="3d" size="sm" :checked.sync="requirePostcode" :disabled="isLoading || !editPostcode" />
                    <EditCombo v-if="canEdit" class="ml-3 mt-2" style="transform:translateY(-1px)"
                        @edit="onEditPostcode"
                        @cancel="onCancelPostcode"
                        @save="onSavePostcode"
                        :edit="editPostcode"
                        :disabled="isLoading"
                        
                    />
                </div>  
            </CRow>             
        </CCardBody>
    </template>
    <template v-if="channel && reviewSettings">
    <CCardHeader class="pt-2 pb-2 top-border"  style="background: #f8f8ff">   
        <div class="float-right">
            <EditCombo v-if="canEdit"
                @edit="onEditReview" 
                @cancel="onCancelEditReview" 
                @save="onSaveEditReview" 
                :edit="editReview" 
                :disabled="isLoading"
            />
        </div>
        <h6 class="m-0 mt-1 mb-1 float-left" style="cursor: pointer">Review Settings</h6>                
        <CSpinner v-if="isLoading && channel != null" class="float-left ml-2 mt-1" size="sm" />
    </CCardHeader>  
    <CCardBody class="pt-2 pb-2 mb-0">
        <div class="form-group form-row mb-0">               
            <label class="col-form-label col-sm-1 strong label-col">Url Template</label>        
            <span v-if="!editReview" class="col form-control text-primary mr-1 pl-2">{{reviewSettings.url}}</span>
            <CInput v-if="editReview" class="mb-0 ml-0 col pl-0 pr-1" add-input-classes="text-black pl-2 strong"
                    v-model="reviewUrl" 
                    :disabled="isLoading"
                    placeholder="https://..."
                    :isValid="isUrlValidOrEmpty"
                    ref="editReviewUrl"
            />
        </div>   
        <div class="form-group form-row mb-0">               
            <label class="col-form-label col-sm-1 strong label-col">Require Confirmation</label>        
            <span v-if="!editReview" 
                  class="col col-form-label" :class="(reviewSettings.confirm ? 'text-danger strong' : 'text-info')"
            >{{reviewSettings.confirm ? "Yes" : "No"}}</span>      
            <CSwitch v-if="editReview"                     
                     class="mt-2 ml-1" color="success" variant="3d" size="sm"
                    :checked.sync="confirmReview"
                    :disabled="isLoading"
            ></CSwitch>     
        </div>   
        <div class="form-group form-row mb-0">               
            <label class="col-form-label col-sm-1 strong label-col">Max Discount</label>        
            <span class="col col-form-label text-info">{{ maxReviewDiscount }}</span>            
        </div>  
        <CAlert v-if="errorMessage" color="danger" class="mb-1 mt-3">{{ errorMessage }}</CAlert>
    </CCardBody>
    </template>
</CCard>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';
import Pseudo from '@/components/orderdb/Pseudo';
import Regex from '@/components/orderdb/Regex';
import Jtl from '@/components/orderdb/Jtl';
import EditCombo from '@/components/EditCombo';
import SellerTools from '@/components/orderdb/SellerTools';
import { getResponseData } from '@/utils';
import { EDIT_CHANNEL, VIEW_PRODUCT, mapPermissions } from '@/permissions';

export default {
    props: {
        channelName: String
    }, 
    data() {
        return {
            editReview: false,
            reviewUrl: null,
            confirmReview: false,
            errorMessage: null,
            editPostcode: false,
            requirePostcode: false,
        }
    },
    components: {
        Pseudo, Regex, Jtl, EditCombo
    },
    computed:
    {
        ...mapPermissions({
            canEdit: EDIT_CHANNEL,
            canViewProduct: VIEW_PRODUCT
        }),
        ...mapGetters({
            merchantId: 'merchantId',
            channel: 'products/channel',
            isLoading: 'products/loading'
        }),
        displayName() {
            return this.channel ? this.channel.displayName : this.channelName;
        },
        isPage() {
            return (this.channel && this.channel.kind == "Page");
        },
        reviewSettings() {
            return this.channel ? this.channel.reviewSettings : null;
        },
        maxReviewDiscount()
        {
            const discount = this.reviewSettings?.maxDiscount;
            console.debug("discount:");
            console.debug(discount);
            console.debug(Number.isNaN(null));
            return (typeof discount == 'number' && !Number.isNaN(discount)) ? (Math.round(discount) + " %") : 'Any';
        },
        channelType() {     
            if (this.channel)
            {
                switch (this.channel.kind)
                {
                    case "OrderDb":
                        return "Order Check";

                    case "Page":
                        return "Content Page";
                }
            }

            return '-';
        },
        orderDbComponent()
        {
            if (!this.channel) {
                return null;
            }

            switch (this.channel.orderDbKind)
            {
                case "regex":
                    return Regex;

                case "pseudo":
                    return Pseudo;
                
                case "jtl":
                    return Jtl;

                case "seller.tools":
                    return SellerTools;

                default:
                    return null;
            }
        }
    }, 
    methods: {
        ...mapActions({
            loadChannel: actionNames.LOAD_CHANNEL,
            updateChannel: actionNames.UPDATE_CHANNEL
        }), 
        async initChannel() {
            await this.loadChannel({channel:this.channelName});  
            if (this.channel != null) {
                this.requirePostcode = this.channel?.requirePostcode;
            }
        },
        onEditReview() {
            this.errorMessage = null;
            this.editReview = true;
            this.reviewUrl = this.reviewSettings?.url;
            this.confirmReview = this.reviewSettings?.confirm;
            const o = this;
            setTimeout(function() {
                o.$refs.editReviewUrl.$el.querySelector("input").focus();
            });
        },
        onCancelEditReview() {
            this.editReview = false;
        },
        async onSaveEditReview() {
            if (this.isUrlValidOrEmpty(this.reviewUrl) === false) {
                return;
            }

            const review = {
                confirmReview: this.confirmReview,
                urlTemplate: this.reviewUrl
            };
            
            try {
                await this.updateChannel({channel:this.channel.name, review});
            }
            catch (e) {
                const error = getResponseData(e);
                if (error) {
                    this.errorMessage = error.message;
                }
                else {
                    this.errorMessage = 'An unknown error occurred. Could not update channel.';
                }

                return;
            }
            
            this.editReview = false;
            this.errorMessage = null;
        },
        onEditPostcode() {
            this.requirePostcode = this.channel?.requirePostcode;
            this.editPostcode = true;
        },
        onCancelPostcode() {
            this.requirePostcode = this.channel?.requirePostcode;
            this.editPostcode = false;
        },
        async onSavePostcode() {
            const general = {
                verifyPostcode: this.requirePostcode
            };
            
            try {                
                await this.updateChannel({channel:this.channel.name, general});
            }
            catch (e) {
                const error = getResponseData(e);
                if (error) {
                    this.errorMessage = error.message;
                }
                else {
                    this.errorMessage = 'An unknown error occurred. Could not update channel.';
                }

                return;
            }

            this.requirePostcode = this.channel.requirePostcode;
            this.editPostcode = false;
            this.errorMessage = null;
        },
        isUrlValid(text) {
            const valid =  /^(https?:\/\/[\w-{}./&?=+%$#()_~\\!:;|]+)$/g.test(text);
            return valid ? null : false;
        },
        isUrlValidOrEmpty(text) {
            return text ? text.length == 0 || this.isUrlValid(text) : null;
        }
    },
    watch:{
        merchantId() {
            this.initChannel();
        },
        channelName()
        {
            this.initChannel();
        }
    },
    created()
    {
        this.initChannel();
    }
}
</script>

<style scoped>
    .edit-btn {
        margin-top: -13px; 
        margin-bottom: -10px; 
        min-width:75px;
    }
    a.nav-link .text-black {
        color: black;
    }
    .text-black {
        color: black;
    }
    header.top-border {
        border-top-style: solid;
        border-top-width: 1px;
    }
    .label-col {
        min-width: 152px;
    }
    .icon-baseline {
        transform: translateY(-2px);
    }
</style>
<style>
    .text-black {
        color: black;
    }
</style>
